<template>
  <div class="page">
    <Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
      :showAdd="$hasAccess('device.seat/add')" addName="新增" @getList="getList" @clearSearch="clearSearch" @addItemInTable="addOne">

      <!--搜索自定义内容-->
      <el-form-item slot="search-item">
        <el-input v-model="searchFormData.group_name" size="small" clearable placeholder="请按小区名称查询"></el-input>
      </el-form-item>

      <!--表格内容-->
      <vxe-table-column slot="table-item" field="group_name" title="站点名称" align="center" min-width="80px" />
      <vxe-table-column slot="table-item" field="service_price" title="占位单价" align="center" min-width="100px">
        <template v-slot="{ row }">
          <div v-for="idx in JSON.parse(row.service_price).length">
            {{JSON.parse(row.service_price)[idx-1].time_slot}}-
            {{JSON.parse(row.service_price)[idx-1].time_slot_2}}时
            {{JSON.parse(row.service_price)[idx-1].unit_price}} 元/分钟
          </div>
        </template>
      </vxe-table-column>
      <vxe-table-column slot="table-item" field="status" title="状态" align="center" min-width="80px">
        <template v-slot="{ row }">
          <span v-if="row.status == 10">上线</span>
          <span v-if="row.status == 20">下线</span>
        </template>
      </vxe-table-column>
       <vxe-table-column slot="table-item" field="free_duration" title="免费时长(分钟)" align="center" min-width="80px" />
      <vxe-table-column slot="table-item" field="max_money" title="最大收费(元)" align="center" min-width="80px" />
      <vxe-table-column slot="table-item" field="force_time" title="生效时间" align="center" min-width="120px" />
      <vxe-table-column slot="table-item" field="off_time" title="下线时间" align="center" min-width="80px" />
      <vxe-table-column slot="table-item" field="update_time" title="更新时间" align="center" min-width="80px" />
      <vxe-table-column slot="table-item" title="操作" align="center" width="220">
        <template v-slot="{ row }">
          <el-button size="small" plain @click="editGroup(row)" v-if="$hasAccess('device.seat/edit')">编辑</el-button>
          <el-button size="small" plain @click="deleteGroup(row)" v-if="$hasAccess('device.seat/upstatus')">
              <span v-if="row.status == 20">上线</span>
              <span v-if="row.status == 10">下线</span>
          </el-button>
        </template>
      </vxe-table-column>
    </Tables>
  </div>
</template>

<script>
import API from '@/service/api'
import Tables from '@/components/tables'

export default {
  name: 'device-seat-list',
  components: {
    Tables
  },
  data () {
    return {
      tableName: '占位信息',
      isLoading: false,
      tableData: [],
      totalPage: 0,
      searchFormData: {
        plug_status: '',
        plug_num: '',
        plug_name: ''
      }
    }
  },
  mounted () {},
  activated () {
    this.$refs.xTable.refreshTable()
  },
  methods: {
    async getList (currentPage, pageSize) {
      const params = {
        token: this.$store.state.user.token,
        page: currentPage,
        size: pageSize
      }
      this.isLoading = true
      const searchData = this.searchFormData
      const res = await API.Device.GetSeatList(params, searchData)
      this.tableData = res.data
      this.totalPage = res.total
      this.isLoading = false
    },
    clearSearch () {
      this.searchFormData = {
        group_name: ''
      }
    },
    addOne () {
      this.$router.push({
        name: 'device-seat-add'
      })
    },
    editGroup (row) {
      this.$router.push({
        name: 'device-seat-edit',
        query: {
          id: row.id
        }
      })
    },
    async sync (row) {
      const params = {
        token: this.$store.state.user.token,
        group_id: row.group_id
      }
      await API.Device.SyncGroup(params)
      this.$notify({
        title: '成功',
        message: '同步成功',
        type: 'success'
      })
    },
    deleteGroup (row) {
      var  $msg='上线';
      if (row.status==10){
        $msg='下线';
      }
      this.$confirm('确定要'+$msg+'吗', '提示', {
        cancelButtonClass: 'btn-custom-cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const params = {
          token: this.$store.state.user.token,
          id: row.id,
          status:row.status
        }
        await API.Device.UpstatusSeat(params)
        this.$notify({
          title: '成功',
          message: '更改状态成功',
          type: 'success'
        })
        this.$refs.xTable.refreshTable()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
